import React, { useEffect, useRef, useState } from 'react'
import { t } from '../../i18n';
import * as T from '../../actions/Types';
import UserChatComponent from '../../components/UserChatComponent';
import AgoraRTC, { IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import suteLogs from '../../utils/log';
import { RemoteStreamUser, iRemoteStreams, localStreamUser } from '../../interfaces/icall';
import RemotePlayer from '../rooms/remote-player';
import { GetFromActivitie, SaveMyActivity } from '../../services/ActivityServices';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';


function PreCallPopup(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const [showDialog, setShowDialog] = useState(false);
    const [audio, setAudio] = useState(false);
    const [chatting, setChatting] = useState(false);
    const [video, setVideo] = useState(true);
    // const [localStream, setLocalStream] = useState<any>();
    // const [joined, setJoined] = useState(false);
    const joined = useRef(false);
    const [published, setPublished] = useState(false);
    //  const [remoteStreams, setRemoteStreams] = useState<any[]>();
    // const empId = localStorage.getItem('EmpGuid') || '';
    const userDisplayName = localStorage.getItem('FirstName');
    const [started, setStarted] = useState(false);
    const [time, setTime] = useState(0);
    const [timer, setTimer] = useState<any>(0);
    const empId = localStorage.getItem('EmpGuid') || '';
    let currentId = localStorage.getItem('EmpId') || 0 as number;
    let orgId = localStorage.getItem('OrgGuid') || '';
    const tmCallInfo = useRef<any>(0);
    const [callInfo, setCallInfo] = useState<any>(undefined);
    const [callerDtl, setCallerDtl] = useState<any>({});
    const initialized = useRef(false)
    let localStream = useRef<localStreamUser>({
        streamID: 0,
        audio: false,
        video: false,
        screen: false,
        localAudioTrack: undefined,
        localVideoTrack: undefined,
        localDetails: { audio: 'YES', video: 'YES' },
        errAudio: false,
        errVideo: false,
        audioDescription: '',
        videoDescription: '',
    });
    const [localUser, setLocalUser] = useState<localStreamUser>(localStream.current);
    let meetingId = useRef('');
    let Lclient: IAgoraRTCClient = ({} as IAgoraRTCClient);
    let callringTimer: any = 0;
    const [client, setClient] = useState<any>(undefined);
    const SuteLog = new suteLogs();
    const token = undefined;
    const appId = process.env.REACT_APP_AGORA_APP_ID as string;
    const [uid, setSid] = useState<string>('');
    let remoteUsers = useRef<RemoteStreamUser[]>([]);
    const [remoteStreams, setRemoteStreams] = useState<RemoteStreamUser[]>([]);
    const [fName, setFname] = useState<string>('');
    const dispatch = useDispatch();
    const callInterval = useRef<any>();

    useEffect(() => {
        console.log('#PRE joined ', joined.current);
    }, [joined.current]);

    useEffect(() => {
        if (!props.openCall) {
            setShowDialog(false);
            dispatch({
                type: T.ADD_INCALL,
                payload: false
            })
            console.log('#PRE openCall false ');
        } else {
            console.log('#PRE openCall true ');
            dispatch({
                type: T.ADD_INCALL,
                payload: true
            })
            setShowDialog(true);
        }
        return () => {
            dispatch({
                type: T.ADD_INCALL,
                payload: false
            })
            clearInterval(callInterval.current);

            console.log('#PRE openCall RETURN ');
        }
    }, [props.openCall])

    useEffect(() => {
        if (!initialized.current) {
            console.log('#Pre Call', 'useEffect');
            setFname(props.selectedUser.FirstName);
            createMeeting();
            // if (user && user.Socket && user.Socket.connected) {
            //     /// initialized.current = true;
            //     console.log('#OPENCALL userfromCallStatus ')
            //     user.Socket.on('userfromCallStatus', (Status: any) => {
            //         console.log('#OPENCALL userfromCallStatus ', Status)
            //         if (Status == 'ONLINE') {
            //             SaveMyActivity({ Type: "CALL", FromId: empId, ToId: props.selectedUser.EmpGuid, OrgGuid: orgId, Status: "CALLING" }).then((res) => {
            //                 setCallerDtl({ ...callerDtl, type: 'audio', msg: 'Calling...' });
            //                 console.log('#OPENCALL After save ', res);
            //                 GetCallDetails();
            //                 callInterval.current = setInterval(() => {
            //                     console.log('#OPENCALL setInterval ');
            //                     setCallEnd();
            //                     leaveMeeting();
            //                 }, 65000)
            //                 if (user.Socket && user.Socket.connected) {
            //                     console.log('#PRE userCall ', props.selectedUser.EmpGuid, meetingId.current)
            //                     user.Socket.emit("userCall", props.selectedUser.EmpGuid, meetingId.current);
            //                 }
            //             });
            //         } else if (Status == 'OFFLINE') {
            //             clearInterval(callInterval.current);
            //             setCallerDtl({ ...callerDtl, msg: fName + ' is Off line' });
            //             user.Socket.emit("leaveUserCall", meetingId.current);

            //             setTimeout(function () {
            //                 leaveMeeting();
            //             }, 3000);

            //         }
            //         else if (Status == 'INCALL') {
            //             clearInterval(callInterval.current);
            //             setCallerDtl({ ...callerDtl, msg: fName + ' is in other call' });
            //             SaveMyActivity({ Type: "CALL", FromId: empId, ToId: props.selectedUser.EmpGuid, OrgGuid: orgId, Status: "CALLING", IsDeleted: true }).then((res) => {
            //                 if (user.Socket && user.Socket.connected) {
            //                     console.log('#PRE leaveUserCall ', meetingId.current)
            //                     user.Socket.emit("leaveUserCall", meetingId.current);
            //                 }
            //                 setTimeout(function () {
            //                     leaveMeeting();
            //                 }, 3000);
            //             });
            //             // callInfo.IsDeleted = true;
            //             // SaveMyActivity(callInfo).then((res) => { });
            //             // console.log('INCALL by callee', callInfo.ToId);
            //         }
            //         else if (Status == 'REJECTED') {
            //             clearInterval(callInterval.current);
            //             setCallerDtl({ ...callerDtl, msg: 'Rejected' });
            //             SaveMyActivity({ Type: "CALL", FromId: empId, ToId: props.selectedUser.EmpGuid, OrgGuid: orgId, Status: "CALLING", IsDeleted: true }).then((res) => {
            //                 if (user.Socket && user.Socket.connected) {
            //                     console.log('#PRE leaveUserCall ', meetingId.current)
            //                     user.Socket.emit("leaveUserCall", meetingId.current);
            //                 }
            //                 setTimeout(function () {
            //                     leaveMeeting();
            //                 }, 3000);

            //             });
            //             // callInfo.IsDeleted = true;
            //             // SaveMyActivity(callInfo).then((res) => { });
            //             // console.log('INCALL by callee', callInfo.ToId);
            //         }
            //         else if (Status == 'ACCEPTED') {
            //             clearInterval(callInterval.current);
            //             console.log('#PRE ACCEPTED ');
            //             setCallerDtl({ ...callerDtl, msg: 'Accepted' });
            //             GoJoin();
            //             clearInterval(callInterval.current);
            //         }
            //         else if (Status == 'ENDED') {
            //             clearInterval(callInterval.current);
            //             console.log('#PRE ENDED ');
            //             setFname(props.selectedUser.FirstName + ' has left');
            //             // setTimeout(function () {
            //             //     leaveMeeting();
            //             // }, 3000);

            //         }
            //     })
            //     console.log('#PRE checkUserCall ')
            //     user.Socket.emit("checkUserCall", props.selectedUser.EmpGuid);
            // } else {
                SaveMyActivity({ Type: "CALL", FromId: empId, ToId: props.selectedUser.EmpGuid, OrgGuid: orgId, Status: "CALLING" }).then((res) => {
                    setCallerDtl({ ...callerDtl, type: 'audio', msg: 'Calling...' });
                    tmCallInfo.current = setInterval(GetCallDetails, 5000);
                });
           // }
        }
        return () => {
            console.log('#PRE RETURN ', showDialog);
            if (initialized.current) {
                // if (user.Socket && user.Socket.connected)
                //     user.Socket.removeListener("userfromCallStatus");
                if (localStream.current && (localStream.current.localAudioTrack || localStream.current.localVideoTrack))
                    leave().then(() => { });
            }
            else
                initialized.current = true
            clearInterval(tmCallInfo.current);
        }
    }, [])

    const GetCallDetails = () => {
        console.log('#PRE GetCallDetails ')
        GetFromActivitie(empId).then((result) => {
            if (result && result.length > 0) {
                console.log('#PRE GetCallDetails ', result[0])
                setCallInfo(result[0]);
            }
        });
    }

    useEffect(() => {
        // if (user && user.Socket && user.Socket.connected)
        //     return
        if (callInfo) {
            if (callInfo.IsAccepted) {
                // clearInterval(tmCallInfo.current);
                console.log('accepted by callee ', callInfo.ToId);
                setCallerDtl({ ...callerDtl, msg: 'Accepted' });
            }
            else if (callInfo.IsRejected) {
                //clearInterval(tmCallInfo.current);
                setCallerDtl({ ...callerDtl, msg: 'Rejected' });
                callInfo.IsDeleted = true;
                SaveMyActivity(callInfo).then((res) => { });
                console.log('LocalInvitationRefused by callee', callInfo.ToId);
            }
            else if (callInfo.IsInCall) {
                // clearInterval(tmCallInfo.current);
                setCallerDtl({ ...callerDtl, msg: callInfo.FirstName + ' is in other call' });
                callInfo.IsDeleted = true;
                SaveMyActivity(callInfo).then((res) => { });
                console.log('INCALL by callee', callInfo.ToId);
            }
        }

    }, [callInfo])

    useEffect(() => {
        // if (user.Socket && user.Socket.connected)
        //     return;
        if (callerDtl && callerDtl.msg) {
            if (callerDtl.msg === 'Accepted') {
                GoJoin()
                if (timer) {
                    clearInterval(timer);
                }
                setTime(0);
                setTimer(calcDuration(+Date.now()));
            } else if (callerDtl.msg === 'in other Call') {

            }
            else {
                console.log('#pre other message ', callerDtl.msg);
            }
        }
    }, [callerDtl.msg])

    const createMeeting = () => {
        const mID = currentId > props.selectedUser.EmployeeId ? props.selectedUser.EmpGuid.split('-')[0] + empId.split('-')[0] : empId.split('-')[0] + props.selectedUser.EmpGuid.split('-')[0];
        setMettingId(mID);
    }
    const setMettingId = (mId: any) => {
        meetingId.current = mId;
        // GoJoin();
    }

    const GoJoin = () => {
        console.log("#pre Conference GoJoin " + empId);
        Lclient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        setClient(Lclient);
    }

    useEffect(() => {
        if (client) {
            console.log("#pre peer calling joinRoom ")
            if (!joined.current) {
                console.log("#pre Conference calling autoJoinRoom")
                //  autoJoinRoom();
                makeJoin(meetingId.current);

            }
        }
    }, [client]);

    const makeJoin = async (mId: any) => {
        console.log("#pre Conference makeJoin ")
        if (joined.current) {
            console.log("#pre Conference calling justLeave ")
            await justLeave();
        }
        if (!joined.current) {
            await client.enableDualStream();
            subscribeEvents();
            let uuid = await client.join(appId, mId, token ? token : null, null);
            // client.getRTCStats()
            setSid(uuid as string);
            try {
                if (!localStream.current.localVideoTrack) {
                    localStream.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
                    localStream.current.errVideo = false
                    localStream.current.video = true
                    localStream.current.localDetails.video = "YES";
                    console.log("#pre camera success");
                }

            } catch (error: any) {
                localStream.current.video = false
                localStream.current.localDetails.video = error.message;
                localStream.current.errVideo = true;
                localStream.current.videoDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                console.log("#pre camera error: " + error);

            }

            try {

                //localStream.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });
                if (!localStream.current.localAudioTrack) {
                    localStream.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({ AEC: true, ANS: true });// await AgoraRTC.createMicrophoneAudioTrack({ encoderConfig: "music_standard" });
                    localStream.current.errAudio = false;
                    localStream.current.audio = true;
                    localStream.current.localDetails.audio = "YES";
                    console.log("#pre Micro phone success");
                }

            } catch (error: any) {
                localStream.current.errAudio = true;
                localStream.current.audio = false;
                localStream.current.localDetails.audio = error.message;
                localStream.current.audioDescription = ((error.message.includes("PERMISSION_DENIED") ? 'PERMISSION_DENIED' : (error.message.includes("NOT_FOUND") ? 'NOT_FOUND' : 'ERROR')));
                console.log("#pre Micro phone error: " + error);
            }

            localStream.current.streamID = uid;
            localStream.current.screen = false;
            // setJoined(true);
            joined.current = true;
            setStarted(true);
            console.log("#pre SuteClient calling publish ");
            try {

                if (localStream.current.localAudioTrack) {
                    await client.publish([localStream.current.localAudioTrack]);
                }
                if (localStream.current.localVideoTrack) {
                    console.log("#pre  localStream.current.video", localStream.current.video);
                    await client.publish([localStream.current.localVideoTrack]);
                }
                setTimeout(function () {
                    setLocalUser(localStream.current);
                    setTimer(calcDuration(+Date.now()));
                   
                }, 1000);

            } catch (error) {
                console.log("#pre publish error: " + error);
            }

            console.log("#pre SuteClient publish successfully");
        }

    }

    const subscribeEvents = () => {
        console.log("#pre SuteClient subscribeEvents");
        if (!client) return;
         client.on('user-joined', handleUserJoined);
        client.on('user-published', handleUserPublished);
        client.on('user-left', handleUserLeft);
        client.on("user-unpublished", handleUserUnpublished);
        // // client.on("stream-published", (evt:any)=>{//calls when local stream published
        // //   published = true;
        // // })
        client.on('network-quality', function (stats: any) {
            //alert('network quality');
            console.log('network quality ' + stats.uplinkNetworkQuality)
            console.log('network quality ' + stats.downlinkNetworkQuality)
            // setQualityDescriptors(stats.uplinkNetworkQuality, $('#uplink-quality-btn'), $('#uplink-quality-icon'))
            //  setQualityDescriptors(stats.downlinkNetworkQuality, $('#downlink-quality-btn'), $('#downlink-quality-icon'));
        });
        client.on("token-privilege-did-expire", function () {
            console.log("onTokenPrivilegeDidExpire")
        })

    }

    const unsubscribeEvents = () => {
        console.log("#pre SuteClient unsubscribeEvents");
         client.off('user-joined', handleUserJoined);
        client.off('user-published', handleUserPublished);
        client.off('user-left', handleUserLeft);
        client.off("user-unpublished", handleUserUnpublished)
        client.off("token-privilege-did-expire", function () {

        })

    };
    const SetPublishedUser = (newUser: IAgoraRTCRemoteUser, PublishedUsr: RemoteStreamUser) => {
        console.log("#Mstate SetPublishedUser", newUser?.uid)
        PublishedUsr.video = newUser ? newUser?.hasVideo : false;
        PublishedUsr.audio = newUser ? newUser?.hasAudio : false;
        PublishedUsr.stream.AudioTrack = newUser ? newUser.audioTrack : undefined;
        PublishedUsr.stream.VideoTrack = newUser ? newUser.videoTrack : undefined;
        PublishedUsr.stream.audio = newUser ? newUser.hasAudio : false;
        PublishedUsr.stream.video = newUser ? newUser.hasVideo : false;
       
       setRemoteStreams(  remoteStreams.map(ru => {
            return ru.uid == newUser?.uid ? PublishedUsr : ru
        })
       )
    }

    const handleUserPublished = (async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
        console.log("#pre SuteClient handleUserPublished " + user.uid + " " + mediaType);
        await client.subscribe(user, mediaType);
        console.log("#pre SuteClient handleUserPublished after subscribe");
        console.log("#pre SuteClient handleUserPublished calling newUserAdded ");
        let RemoteUser = remoteStreams.filter(u => { return u.uid == user?.uid });
        if(RemoteUser && RemoteUser.length > 0) {
          SetPublishedUser(user,RemoteUser[0]);
        }
        else
      await newUserAdded();

        newUserAdded()
    });

    const handleUserJoined = async (user: any) => {
        console.log("#Mstate UserJoined", new Date())
        console.log(" Mstate1 UserJoined " + user.uid);       
        await newUserAdded()
    }
    const handleUserUnpublished = async(user: IAgoraRTCRemoteUser, mediaType: any) => {
        console.log("#pre SuteClient handleUserUnpublished " + user.uid);
        console.log("#pre SuteClient handleUserUnpublished calling newUserAdded ");
        await client.unsubscribe(user, mediaType);
        let RemoteUser = remoteStreams.filter(u => { return u.uid == user?.uid });
          if(RemoteUser && RemoteUser.length > 0) {
            SetPublishedUser(user,RemoteUser[0]);
          }
          else
        await newUserAdded();
    };

    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
        console.log("#pre SuteClient handleUserLeft calling newUserAdded ");
        timer && clearInterval(timer);
        setFname(props.selectedUser.FirstName + ' has left');
        const rUsers = remoteStreams.filter(r => r.uid != user.uid);
        setRemoteStreams([...rUsers]);
        leaveMeeting();
        setTimeout(function () {
            props.cancelCall();
        }, 3000);

    }

    const newUserAdded = () => {
        let grpUsrs;
        let usr: any;
        let isValid = true;
        let pinId: any = undefined;

        let TempUsers = [] as RemoteStreamUser[];
        let TempUser = {} as RemoteStreamUser;
        let rStream;
        if (client && client.remoteUsers && client.remoteUsers.length == 1) {

            if (props.selectedUser && props.selectedUser.EmployeeId && props.selectedUser.EmployeeId > 0) {
                const usr = client.remoteUsers[0];
                TempUser = {} as RemoteStreamUser
                rStream = {} as iRemoteStreams;
                rStream.uid = usr.uid
                rStream.VideoTrack = usr.videoTrack;
                rStream.AudioTrack = usr.audioTrack;
                rStream.audio = Boolean(usr.audioTrack ? true : false);
                rStream.video = Boolean(usr.videoTrack ? true : false);
                rStream.screen = false;
                TempUser.stream = rStream;
                TempUser.video = Boolean(usr.videoTrack ? true : false);
                TempUser.audio = Boolean(usr.audioTrack ? true : false);
                TempUser.screen = false;
                TempUser.uid = usr.uid;
                TempUser.pinned = false
                TempUser.EmpGuid = props.selectedUser.EmpGuid
                TempUser.profile = props.selectedUser;
                TempUser.FirstName = props.selectedUser.FirstName //+ (TempUser.screen ? '(Screen)' : '');

                TempUsers.push(TempUser);

                //suteClient.remoteStreams = TempUsers;
                remoteUsers.current = TempUsers;
                setRemoteStreams([...remoteStreams, ...TempUsers]);
            };


        }

    }

    async function justLeave() {
        console.log("Conference justLeave")
        await leave();
    }

    const cancelMeeting = async () => {
        // if (user.Socket && user.Socket.connected) {
        //     console.log('#PRE userfromCallStatus CANCEL')
        //     user.Socket.emit("userfromCallStatus", meetingId.current, 'CANCEL');
        // }
        cancelCall();
    }
    const leaveMeeting = async () => {
        console.log("Conference leaveMeeting")
        dispatch({
            type: T.OPEN_CALL,
            payload: false
        })
        if (!joined.current) {
            setShowDialog(false);
            // setJoined(false);
            joined.current = false;
            setStarted(false);
            cancelCall();
            return;

        }
        localStorage.removeItem('newMeetingType');
        localStorage.removeItem('newMeetingid');
        //setJoined(false);
        joined.current = false;
        setStarted(false);
        await leave();
        console.log("Conference leaveMeeting levave metting")
        console.log("Conference suteClient.leaveMeeting dispatch remove  user from room")
    }

    async function leave() {

        console.log("Leave");

        if (!localStream) {
            /// setCallerDtl({ ...callerDtl, openDialog: false });
            return;
        }
        try {

            if (localStream.current.localVideoTrack) {
                // localStream.localVideoTrack.setEnabled(false);
                localStream.current.localVideoTrack.stop()
                localStream.current.localVideoTrack.close()
            }

            if (localStream.current.localAudioTrack) {
                // localStream.localAudioTrack.setEnabled(false);
                localStream.current.localAudioTrack.stop()
                localStream.current.localAudioTrack.close()
            }

            setCallEnd();
            unsubscribeEvents();
            // console.log(" c 1 ", client.connectionState)

            await client.leave().then(() => {
                localStream.current =
                {
                    streamID: 0,
                    audio: false,
                    video: false,
                    screen: false,
                    localAudioTrack: undefined,
                    localVideoTrack: undefined,
                    localDetails: { audio: 'YES', video: 'YES' },
                    errAudio: false,
                    errVideo: false,
                    audioDescription: '',
                    videoDescription: '',
                }

                // setLocalUser(localStream);
                //setJoined(false);
                joined.current = false;
                /// cancelCall();
                setStarted(false);
                setTime(0);
                setShowDialog(false);
                clearInterval(timer._id);
                /// setCallerDtl({ ...callerDtl, openDialog: false });
            }).catch(() => {
                //if (navigation) {
                ///      setCallerDtl({ ...callerDtl, openDialog: false });
                // }
            });
        } catch (error) {
            //if (navigation) {
            ///   setCallerDtl({ ...callerDtl, openDialog: false });
            // navigation(0)
            // }

        }
    }

    const onAudioClick = async (evt: any) => {

        if (localStream.current.errAudio) {
            let inum = localStream.current.audioDescription == 'NOT_FOUND' ? 3 : 0;
            if (localStream.current.errVideo)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;

            return;
        }

        if (!localStream.current.localAudioTrack)
            return;
        console.log("Conference onAudioClick " + !localStream.current.audio)
        //localM.audio = !localStream.current.audio;
        localStream.current.audio = !localStream.current.audio;
        if (localStream?.current.audio) {
            localStream.current.localAudioTrack?.setEnabled(true);
            //stream.localAudioTrack?.play();

        } else {
            // stream.localAudioTrack.stop();
            localStream.current.localAudioTrack?.setEnabled(false);
        }
        setLocalUser(localStream.current);
    }

    const onVideoClick = (evt: any) => {
        console.log("#in onVideoClick" + !localStream.current.video)
        if (localStream.current.errVideo) {
            let inum = localStream.current.videoDescription == 'NOT_FOUND' ? 4 : 1;
            if (localStream.current.errAudio)
                inum = localStream.current.audioDescription == 'NOT_FOUND' ? 5 : 2;

            return;
        }
        if (!localStream.current.localVideoTrack)
            return;
       
        localStream.current.video = !localStream.current.video;
        if (localStream?.current.video) {
            localStream.current.localVideoTrack?.setEnabled(true)
        }
        else {
            localStream.current.localVideoTrack?.setEnabled(false)
        }
        setLocalUser(localStream.current);
    }

    const cancelCall = () => {
        if (callInfo) {
            callInfo.IsCancel = true;
            SaveMyActivity(callInfo).then((res) => {
                props.cancelCall();
            });
        } else
            props.cancelCall();

    }

    const calcDuration = (tm: number) => {
        return setInterval(() => {
            updateTime(+Date.now() - tm);
        }, 1000, tm)
    }
    const updateTime = (t: number) => {
        setTime(t);
    }
    const setCallEnd = () => {
        console.log('#OPENCALL setCallEnd ', callInfo);
        // if (user.Socket && user.Socket.connected) {
        //     console.log('#OPENCALL ENDED ', props.selectedUser.EmpGuid);
        //     user.Socket.emit("userfromCallStatus", meetingId.current, 'ENDED');
        // }
        if (callInfo) {
            callInfo.IsDeleted = true;
            callInfo.Status = 'CALL_ENDED';
            SaveMyActivity(callInfo).then((res) => { });
        }
    }
    return (<>
        {showDialog ? <div className={`SideNav text-left`} >
            <div className="d-flex pr-0 preCallPopup sideNavBody pl-0">
                <div className="align-content-start w-100">
                    <div className="videoCallBox col-12 text-center mb-2 mt-3 overflow-hidden" style={{ height: '24vh' }}>
                        {!started && localStream ?
                            <> <div id={`dom-localUser`} className={`agora-rtc-video fluidCard rotateY180deg memberProfilePicture} ${Boolean(localUser.video) ? '' : 'd-none'}`}>

                                <span className="participantName">{userDisplayName}</span></div>
                                <div className={`agora-rtc-video ${Boolean(localUser.video) ? 'd-none' : ''} `}>
                                    <img className="m-auto" src={props.selectedUser.profileImage ? props.selectedUser.profileImage : "/assets/media/images/sute_user_default.png"} />
                                    <span className="participantName">{userDisplayName}</span>
                                </div>
                            </>
                            : ''}
                        {remoteStreams && remoteStreams.map((rsobj: any) => {
                            return (<RemotePlayer
                                domId={`dom-${rsobj.uid}`}
                                key={`${rsobj.uid}`}
                                id={`${rsobj.stream.uid}`}
                                account={''}
                                streamID={rsobj.uid}
                                stream={rsobj.stream}
                                video={rsobj.video}
                                audio={rsobj.audio}
                                local={true}
                                volume={35}
                                className={''}
                                profileImage={rsobj?.profile?.ProfileImageURL}
                            />)
                        })}
                        {/* <img className="memberProfilePicture" src="/assets/media/svg/profile/male-user.PNG" /> */}
                    </div>
                    {started ? <><div className="col-12 text-center text-light">
                        <h6 className="m-0">{fName}</h6>
                        {/* <p className="text-muted mb-2">{time>3600000?moment.utc(time).format('H:m:ss'):moment.utc(time).format('m:ss')}</p> */}
                    </div></> : <><div className="col-12 text-center text-light">
                        <h6 className="m-0">{fName}</h6>
                        <p className="text-muted mb-2">{callerDtl.msg ? callerDtl.msg : 'Calling...'}</p>
                    </div></>}
                    {started ? <div className="col-12 text-center memberProfileActions">
                        {localUser.audio ? <img onClick={onAudioClick} title={t('title.mute')} src="/assets/media/images/collaborate/mic.svg" /> :
                            <img onClick={onAudioClick} title={t('title.unmute')} src="/assets/media/images/collaborate/mic-inactive.svg" />}
                        {localUser.video ? <img onClick={onVideoClick} title={t('title.video_off')} src="/assets/media/images/collaborate/camera.svg" /> :
                            <img onClick={onVideoClick} title={t('title.video_on')} src="/assets/media/images/collaborate/camera-inactive.svg" />}
                        {/* <img onClick={() => setChatting(true) } title={t('chat.chat')} src="/assets/media/svg/profile/message.svg" />
                        <img title={t('title.files')} src="/assets/media/svg/profile/media.svg" />
                        <img title={t('calendar.calendar')} onClick={props.showSchedue} src="/assets/media/svg/profile/calendar.svg" /> */}
                        <img title={t('title.end_call')} onClick={leaveMeeting} src="/assets/media/images/collaborate/leave.svg" alt="" />
                    </div> :
                        <div className="col-12 text-center memberProfileActions">
                            <img title={t('title.end_call')} onClick={cancelMeeting} src="/assets/media/images/collaborate/leave.svg" alt="" />
                        </div>
                    }
                    <div className="col-12 p-0">
                        <hr className="mb-2" />
                    </div>
                    {chatting ? <div className="col-12" style={{ height: '36vh' }}>
                        <UserChatComponent openChat={chatting} closeChat={() => setChatting(false)}
                            selEmpName={props.selectedUser.FirstName}
                            selEmpGuid={props.EmpGuid} mini={true} />
                    </div> : ''}
                </div>
            </div>
        </div> : ''}</>
    )
}

export default PreCallPopup;

