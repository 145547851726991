import React, { useEffect, useRef, useState } from 'react';
import { globalStore } from '../store/global_store';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';
import { useLocation } from 'react-router';
import { GetContacts, Logout, UpdateLog } from '../services/UserActions';
import { Modal } from 'react-bootstrap';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer'
import { UpdateLastActivityTime } from '../services/RoomsService';
//import io, { Socket } from "socket.io-client";
import { getGroupsByEmpId } from '../services/ContactsService';
import * as T from '../actions/Types';
import { useNavigate } from 'react-router-dom';
import { isChrome, browserName } from "react-device-detect";
import PreCallPopup from '../pages/myoffice/PreCallPopup';
import { GetUnseenUserMessagesCount } from '../services/ChatServices';
import VirtuvalAssistant from './classic/VirtuvalAssistant';
const rta_url = process.env.REACT_APP_API as string;

const WindowFocusHandler = () => {
  const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
  const location = useLocation()
  const dispatch = useDispatch();
  const [showTimeModal, setShowTimeModal] = useState(false);
  const modelTimeoutRef = useRef<HTMLDivElement>();
  const interval = useRef<NodeJS.Timeout>();
  const empId = localStorage.getItem('EmpGuid') as string;
  //const [socket, setSocket] = useState<Socket>();
  let isDone = useRef<Boolean>(false);
  const history = useNavigate();
  let loopGroups = useRef<any>(0);
  const empObj = { EmployeeId: 0, FirstName: '', LastName: '', EmpGuid: '', OfficeEmail: '', Mobile: '', status: '' }
  const selectUser = useRef(empObj);
  const pingInterval = useRef<number>();

  useEffect(() => {
    console.log("#TEST WFH ", user.isAuthenticated, isDone.current);
    console.log("#TEST RTA_URL ", rta_url);

    if (user.isAuthenticated && !isDone.current) {
      console.log("#TEST WFH 2", user.empGUID);

      isDone.current = true;
      // if (!socket) {

      //   try {
      //     console.log("#TEST browser", browserName);

      //     let IoSocket = (isChrome) ? io(rta_url, {
      //       transports: ['websocket'],
      //       reconnection: false,
      //       reconnectionAttempts: 5,
      //       // autoConnect: false,
      //     }) : io(rta_url, { transports: ['websocket'], reconnection: false, reconnectionAttempts: 5 });

      //     IoSocket.on("connect_error", (error) => {
      //       console.log("#TEST WFH ERROR ", error);
      //       setSocket(IoSocket)
      //       if (IoSocket.active) {
      //         console.log("#TEST WFH ERROR ACTIVE", error.message)
      //         // temporary failure, the socket will automatically try to reconnect
      //       } else {
      //         console.log("#TEST WFH ERROR INACTIVE", error.message);
      //         // the connection was denied by the server
      //         // in that case, `socket.connect()` must be manually called in order to reconnect
      //         // console.log(error.message);
      //       }
      //     });

      //     // setSocket( as any, );
      //     IoSocket.on('connect', function () {
      //       setSocket(IoSocket)
      //       console.log('#TEST socket connected');
      //     });
      //     IoSocket.on("disconnect", (reason) => {
      //       console.log('#TEST socket DISconnected', reason);
      //       if (reason == 'io client disconnect')
      //         history("/")
      //       else
      //         window.location.href = "/login";
      //     });

      //   } catch (error) {
      //     console.log("#TEST WFH ERROR: " + error)
      //   }

      // }
      interval.current = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
        setElapsed(Math.ceil(getElapsedTime() / 1000 / 60))
      }, 1000)
      //start();
    }
    else {
      if (!user.isAuthenticated && isDone.current) {
        console.log('#TEST forceDisconnect')
        isDone.current = false;
        // socket?.disconnect();
        // setSocket(undefined);
        dispatch({ type: T.ADD_INCALL, payload: false })
        dispatch({ type: T.ADD_INCHAT, payload: false })
      }
      if (interval.current?.hasRef)
        clearInterval(interval.current);

    }
    return () => {

      // if (interval.current?.hasRef)
      //   clearInterval(interval.current);
    }
  }, [user.isAuthenticated]);

  // useEffect(() => {
  //   if (!socket)
  //     console.log('#TEST  SOCKET LESS');
  //   if (socket) {
  //     console.log('#TEST  SOCKET', socket.id);
  //     dispatch({
  //       type: T.ADD_SOCKET,
  //       payload: socket
  //     })
  //   }
  //   if (socket && socket.connected)
  //     console.log('#TEST SOCKET connected', socket.id);
  //   if (socket && socket.disconnected)
  //     console.log('#TEST SOCKET disconnected', socket.id);

  //   if (user?.OrgGuid && user?.OrgGuid?.length > 0 && user?.empGUID?.length > 0 && socket && socket.connected) {
  //     console.log('#TEST enterRoom')
  //     socket?.emit("enterRoom",
  //       {
  //         empGuid: user.empGUID,
  //         name: user.FirstName,
  //         room: user.OrgGuid
  //       }
  //     );
  //     socket.on('pong', onHeart);
  //     onHeart();
  //     socket.on('userUpdated', (room) => {
  //       console.log("#TEST userUpdated " + room);
  //       dispatch(GetContacts(user.OrgGuid, user.empGUID) as any);
  //       dispatch(GetUnseenUserMessagesCount(user.empGUID) as any);
  //     })
  //     socket.on('groupUpdated', (room) => {
  //       console.log("#TEST groupUpdated " + room);
  //       dispatch(getGroupsByEmpId(user.empGUID) as any)
  //     })
  //     socket.on('userIncall', (UserId: any) => {
  //       console.log('#OPENCALL userIncall', UserId, user.openCall);
  //       socket.emit("userIncall", UserId, user.InCall ? 'TRUE' : 'FALSE');
  //     })
  //   }
  //   else if (user?.OrgGuid?.length > 0 && user?.empGUID?.length > 0 && socket && !socket.connected) {
  //     loopGroups.current = setInterval(getGroups, 5000);
  //   }
  //   return () => {
  //     clearInterval(loopGroups.current);
  //   }
  // }, [socket]);

  const getGroups = () => {
   // dispatch(getGroupsByEmpId(user.empGUID) as any);
  }

 

  // useEffect(() => {

  //   if (user.Socket) {
  //     console.log('#TEST User Socket')
  //   }
  //   else console.log('#TEST NOT User Socket')

  // }, [user.Socket]);

  useEffect(() => {

    if (user.IsGuest) {
      interval.current = setInterval(() => {
        console.log('useIdleTimer setInterval')
        setRemaining(Math.ceil(getRemainingTime() / 1000));
        setElapsed(Math.ceil(getElapsedTime() / 1000 / 60))
      }, 1000);
    }

  }, [user.IsGuest]);

  const onFocus = () => {
    globalStore.focus(true);

  };
  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    //console.log('Tab is blurred');
    globalStore.focus(false);
  };


  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Specify how to clean up after this effect:
    if (user.isAuthenticated) {

      const orgId = localStorage.getItem('OrgGuid') as string;
      // dispatch(GetContacts(orgId, empId));
    }
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);
  useEffect(() => { 
    console.log('LOCATION',location.pathname)
  }, [location]);

  const timeout = 1_000 * 60 * 60;
  const promptBeforeIdle = 1_000 * 120;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [elapsed, setElapsed] = useState<number>(0)
  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  const onActive = () => {
    console.log('useIdleTimer onActive')
    setShowTimeModal(false);
  }

  const onPrompt = () => {
    console.log('useIdleTimer onPrompt')
    if (user.isAuthenticated || user.IsGuest)
      setShowTimeModal(true)
    else
      activate();
  }

  const onIdle = () => {
    console.log('useIdleTimer onIdle')
    leaveSession();
    //history('/logout')
  }

  const onAction = (event?: Event, idleTimer?: IIdleTimer) => {
    console.log('useIdleTimer onAction', idleTimer?.getRemainingTime())

  }
  const { getRemainingTime, activate, getElapsedTime, start, reset, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    onAction,
    promptBeforeIdle,
    throttle: 500,
    startManually: false,
    disabled: !user.isAuthenticated && !user.IsGuest,
  });
  const handleStillHere = () => {
    console.log('useIdleTimer handleStillHere')
    activate()
    setShowTimeModal(false);
  }

  useEffect(() => {
    console.log('useIdleTimer elapsed', remaining)
    if (elapsed > 0) {
      console.log("useIdleTimer elapsed 2--> ", elapsed)
      if (user.isAuthenticated) {
        UpdateStatus();
      }
    }

  }, [elapsed])
  const UpdateStatus = async () => {

    const form = new FormData();
    // form.append("GroupGuid", roomDtls.GroupGuid);
    if (user.isAuthenticated) {
      form.append("EmployeeGuid", empId);
      await UpdateLastActivityTime(form);
    }
  }
  const leaveSession = () => {
    if (user.empData.EmpGuid && user.empData.EmpGuid.length > 0) {
      dispatch({
        type: T.SELECTED_USER,
        payload: undefined
      })

      dispatch({
        type: T.OPEN_CALL,
        payload: false
      })
      if (user.isAuthenticated)
        dispatch(Logout(user.empData.EmpGuid, undefined) as any);


    }
    if (user.IsGuest)
      window.location.href = "/login";
  }

  const getUserDetails = (eid: any) => {
    if (!user.contacts) return;
    //console.log(" cons 5", props.user.contacts);
    const selectedUserAr = user.contacts.filter((account: any) => { if (account.EmpGuid === eid) return account; })
    if (selectedUserAr.length > 0)
      selectUser.current = selectedUserAr[0];
    console.log('#OPENCALL 2', user.openCall, selectUser.current);

  }
  useEffect(() => {
    console.log('#OPENCALL', user.openCall, user.selectedUser);
    if (user.openCall)
      getUserDetails(user.selectedUser);
    else
      selectUser.current = empObj;
  }, [user.openCall]);

  useEffect(()=>{

    let path = location.pathname.slice(1,location.pathname.length)
    
    if(path != null)
    {
      let pagename = path === 'pod' ? 'dashboard' : path === 'produce' ? 'Mydesk' : path ==='resources' ? 'HelpResources' : path === 'my-profile' ? 'Profile' : path === 'earn-learn' ? 'OfficeLobby' : path === 'users' ? 'Employee' : path === 'groups' ? 'Teams' : ''
      if (!user.isAuthenticated || path === 'Induction' || path === 'ManageBulletinBoard' || path === 'calendar' || path.includes('conference'))
        return ;
      else if(pagename != '' )
        UpdateLog(user.empGUID,pagename,'')
      else
        UpdateLog(user.empGUID,path,'')
    }

  },[location])

  const cancelCallLocal = () => {

    dispatch({
      type: T.SELECTED_USER,
      payload: undefined,
    })

    dispatch({
      type: T.OPEN_CALL,
      payload: false
    })
    console.log('#OPENCALL cancelCallLocal ', user.openCall);
   
  }
  return <>
    <Modal ref={modelTimeoutRef} show={showTimeModal} backdrop='static' className="text-light" onEscapeKeyDown={(e: any) => { e.preventDefault(); }}>
      <Modal.Body>
        {timeTillPrompt > 0 && (
          <p>
            {timeTillPrompt} {seconds} until prompt
          </p>
        )}
        <h5>Do you want to stay signed in?</h5><br />
        <h6>Your session is about to expire. You'll be automatically signed out.</h6>
        <p>Leaving session in {remaining} seconds</p>
        <button type="button" className="btn btn-SeaGreen" onClick={leaveSession}>Sign out now</button>
        &nbsp; &nbsp;<button type="button" className="btn btn-secondary" onClick={handleStillHere}>Stay Signed In</button>
      </Modal.Body>
    </Modal>
    {user.openCall && selectUser.current.EmployeeId > 0 ? <PreCallPopup cancelCall={cancelCallLocal} type={'audio'}
      openCall={user.openCall} selectedUser={selectUser.current} /> : ''}
    {
      user.isAuthenticated && !location.pathname.includes('/conference/') ? <VirtuvalAssistant /> : ''
    }

  </>;
};

export default WindowFocusHandler;