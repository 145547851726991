import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../../assets/images/sute-logo-white.png'
import { GoBellFill } from "react-icons/go";
import styles from '../header/header.module.scss'
import { getEmpDetailsByEmpGuid, getUserStatus, updateUserStatus } from "../../../services/UserActions";
import { StoreClass } from "../../../store/Store";
import { tt as t } from "../../../i18n";
import { useLocation, useNavigate } from "react-router-dom";

const staticStatus = [
  { id: "Available", color: "green", title: t("profile.in") },
  { id: "Away", color: "orange", title: t("profile.out") },
  { id: "Busy", color: "red", title: t("profile.busy") },
  //{ id: "Offline", color: "gray", title: "Offline" }
];
function getStatusColor(status: string) {
  const sts = staticStatus.find(s => s.id === status);
  return sts ? sts.color : "gray";
}

function Header(props: any) {
  const location = useLocation();
  const { name} = props;
  const navigate = useNavigate();
  const date = new Date();
  const dayName = date.toLocaleDateString('en-GB', { weekday: 'long' });
  const formattedDate = `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'short' })} ${date.getFullYear()}`
  const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.empData.EmpGuid || user.empData.EmpGuid === '') {
      dispatch(getEmpDetailsByEmpGuid(user.empGUID) as any);
      dispatch(getUserStatus(user.empGUID) as any);
    }
   
    setMessage(user.statusMsg);
  }, []);

  const changeStatus = (status: string) => {
    updateStatus(status, user.statusMsg);
  }
  
  const updateStatus = (status: string, msg: string) => {
    if (!user.empGUID) return;
    const form = new FormData();
    form.append('status', status);
    form.append('statusMessage', msg);
    form.append('empGuid', user.empGUID);
    dispatch(updateUserStatus(form) as any);
  }

  const onLogo = () => {
    if(!location.pathname.includes('/conference/'))
    navigate('/pod')
  }

  return (<>
    <div className="menubar">
      <Navbar expand="lg" className={styles.navbar}>
        <Navbar.Brand className="hand" onClick={onLogo}> <img src={logo} className={styles.w90p} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className={styles.w71} navbarScroll>
            <Nav.Item className={styles.navlink}>{name}</Nav.Item>
          </Nav>
          <div className={styles.rightnav}>
            <h6 className="text-white fs-14 mr12">
              <span>{dayName}</span><br />
              <span>{formattedDate}</span>
            </h6>
            <div className="dropdown">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className={styles.headerdropdown}>
                  <span className={styles.bubblegreen} style={{ backgroundColor: getStatusColor(user.status) }}></span>{user.status}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {staticStatus.map((status) => (
                    <Dropdown.Item className={styles.headerdropdown} key={status.id} onClick={() => changeStatus(status.id)}>
                      <span className={styles.bubblegreen} style={{ backgroundColor: status.color }}></span> {status.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <span>
              <GoBellFill className={styles.notification} />
              <b className={styles.notificationbubble}></b>
            </span>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  </>)
}

export default Header;