import React, { useState, useEffect, useRef } from "react";
import lobyimg from "../../../assets/images/office-loby.png"
import roomsimg from "../../../assets/images/rooms.png"
import chubimg from "../../../assets/images/collaboration-hub.png"
import wcimg from "../../../assets/images/wellness.png"
import hrimg from "../../../assets/images/hangout-room.png"
import resourcesimg from "../../../assets/images/contact.png"
import logoutimg from "../../../assets/images/logout.png"
import { IoIosAlbums } from "react-icons/io";
import { MdDesktopWindows } from "react-icons/md";
import { BiSolidFolderOpen } from "react-icons/bi";
import { FaRegCalendarAlt } from "react-icons/fa";
import styles from "../menus/menus.module.scss"
import Card from "../Card"; 
import { useSelector , useDispatch} from "react-redux";
import { StoreClass } from "../../../store/Store";
import { useNavigate , Link, useLocation } from "react-router-dom";
import * as T from '../../../actions/Types';
import { Logout } from "../../../services/UserActions";
import Employee from "../employee";
import { tt as t } from '../../../i18n';

function Menus(props: any) {
    const { menuName } = props;
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedMenu, setSelectedMenu] = useState('')
    const [defaultRooms, setDefaultRooms] = useState<any>({
        collaborate: {},
        conference: {},
        socialHangout: {},
        earnLearn: {},
        produce: {},
      });
    const location = useLocation();

    const userLogout = () => {
        dispatch({
            type: T.SELECTED_USER,
            payload: undefined
        })

        dispatch({
            type: T.OPEN_CALL,
            payload: false
        })
        dispatch(Logout(user.empGUID, navigate) as any);
    };

    useEffect(() => {
        if (menuName)
            setSelectedMenu(menuName)
    }, [menuName])

    useEffect(()=>{
      if(location.pathname === '/my-profile')
      {
        setSelectedMenu('')
      }
      else
      {
        setSelectedMenu(location.pathname.slice(1,location.pathname.length).toLocaleUpperCase());
      }
    },[location])
    return (<>
        <div className="sidenav">
            <Employee emp={user} />
            <div className="menu-section p-0">
                <Card title="Menu">
                    <ul className="p-2">
                        <Link to="/produce">
                            <li className={selectedMenu === 'PRODUCE' ? styles.menuactive : ''} onClick={() => setSelectedMenu('PRODUCE')}><span><MdDesktopWindows /></span><span className="menu-text">{t('menu.desk')}</span></li>
                        </Link>
                        <Link to="/earn-learn">
                            <li className={selectedMenu === 'LOBBY' ? styles.menuactive : ''} onClick={() => setSelectedMenu('LOBBY')}><span><img src={lobyimg} /></span><span
                                className="menu-text">{t('menu.lobby')}</span></li>
                        </Link>
                        <Link to="/files">
                            <li className={selectedMenu === 'FILES' ? styles.menuactive : ''} onClick={() => setSelectedMenu('FILES')}><span><BiSolidFolderOpen /></span><span
                                className="menu-text">{t('menu.files')}</span></li>
                        </Link>
                        <Link to="/calendar">
                            <li className={selectedMenu === 'CALENDAR' ? styles.menuactive : ''} onClick={() => setSelectedMenu('CALENDAR')}><span><FaRegCalendarAlt /></span><span className="menu-text">{t('menu.calendar')}</span></li>
                        </Link>
                        <Link to="/rooms">
                            <li className={selectedMenu === 'ROOMS' ? styles.menuactive : ''} onClick={() => setSelectedMenu('ROOMS')}><span><img src={roomsimg} /></span><span className="menu-text">{t('menu.room')}</span></li>
                        </Link>
                        <Link>
                            <li className={selectedMenu === 'HUB' ? styles.menuactive : ''} ><span><img src={chubimg} /></span><span
                                className="menu-text">{t('menu.hub')}</span></li>
                        </Link>
                        <Link to="/wellness">
                            <li className={selectedMenu === 'WELLNESS' ? styles.menuactive : ''} ><span><img src={wcimg} /></span><span
                                className="menu-text">{t('menu.wellness')}</span></li>
                        </Link>

                        {/* <Link >
                            <li className={selectedMenu === 'HANGOUT' ? styles.menuactive : ''} ><span><img src={hrimg} /></span><span
                                className="menu-text">{t('menu.hangout')}</span></li>
                        </Link> */}

                        <Link to="/resources">
                            <li className={selectedMenu === 'RESOURCES' ? styles.menuactive : ''} onClick={() => setSelectedMenu('RESOURCE')}><span><img src={resourcesimg} /></span><span className="menu-text">{t('menu.resource')}</span></li>
                        </Link>

                    </ul>
                </Card>

            </div>
            {/* <div className="checkin-menu-btn">
                <ul>
                    <li className="mb-0"><span><IoIosAlbums /></span><span
                        className="menu-text">{t('menu.in')}</span></li>
                </ul>
            </div> */}
            <div className="gradientBox h-auto ml-0 mt-2">
                <ul>
                    <li className="p-2 mb-0" onClick={userLogout}><span><img src={logoutimg}/></span><span
                        className="menu-text">{t('menu.logout')}</span></li>
                </ul>
            </div>
        </div>
    </>)
}

export default Menus;