const en_us = {
    'welcome-sute': "Welcome to SUTE Family##Why Choose SUTE?##For Founders: Scale with ease. We empower you to manage, connect, and grow your team—whether you’re across the street or across the globe.##For Remote Companies: Keep the creativity flowing and the collaboration seamless. We keep your team stay engaged, productive, and in sync.##For Entrepreneurs: You’ve got the vision. We’ve got the tools to make it happen. Simplify your workflow and focus on what matters most.",
    'welcome': "Welcome",
    'back': "back",
    'knowmore' : "Know More",
    'fname' : "First name",
    'lname' : "Last name",
    'phno' : "Phone number",
    'role': "Designation",
    'button': {
        'login': "LOGIN",
        'submit' : "SUBMIT"
    },
    'login': {
        'mail': "Email",
        'pwd': "Password",
        'lbl': "Sign in",   
        'remember':"Remember",
        'forgot': "Forgot",
        'reset': "Reset",
        'new': "New",
        'cnfm': "Confirm",
        'err': {
            "mail": "Please enter mail",
            "pwd": "Please enter password",
            "both": "Please enter mail and password"
        }
    },
    'reset' :{
        'txt': "Welcome back! Please reset your password to get started",
        'err': {
            "notmatch": "Password does not match",
            "length": "Must not exceed 20 characters and should be more than 6 characters",
            "norms": "Must contain at least one lower case, one upper case, one digit and one special character"
                }
    },
    'forgot' : {
          'txt' : "Please provide your email, we'll send you the reset link",
          'getlink' : "GET LINK"
    },
    'profile' :{
        'txt': "My Profile",
        'in' : "Available",
        'busy': "Busy",
        'out' : "Away",
        'id' : "Employee ID",
        'func' : "Function",
        'about' : "About"
    },
    'menu' :{
        'desk' : "My desk",
        'lobby' : "Office lobby",
        'files' : "Files",
        'calendar' : "My calender",
        'room' : "Conference room",
        'hub' : "Collaboration hub",
        'wellness' : "Wellness center",
        'hangout' : "Hangout room",
        'resource' : "Help & Resources",
        'in' : "Check in",
        'logout' : "Logout"
    },
    'feedback':{
        'feed' : "Feedback",
        'type' : "type",
        'progress' : "Progress updates"
    },
    'resources':{
        'txt1' : "COMMUNICATION THAT INSPIRES (PART 2)",
        'txt2' : "This podcast features host John who walks through the last two questions he asks himself to make sure he’s an inspirational communicator. Also, there is discussion around how communication should empower others to act and why pairing communication with application will equip others to act."
    },
    'conference':{
        'leaveMeet' : 'Do you want to Leave from meeting',
        'leave' : 'Leave',
        'stay' : 'Stay',
        'exit' : 'Exit',
        'me' : 'Me'
    },
    'room':{
        'join':'Join'
    },
    'mfeedback':{
        'feedback': 'Feedback',
        'meeting' : 'How was the meeting ?',
        'rate' : 'How do you rate ?',
        'write' : 'Write your feedback',
        'later' : 'LATER',
        'submit' : 'SUBMIT'
    },
    'roomjoin':{
        'sound' : 'Produce sounds to check if the mic works.',
        'microphone' : "Microphone can't found",
        'cameracheck' : 'Move in front of the camera to check if it works.',
        'selectmic' : 'Select Microphone',
    },
    'chat':{
        'chat':'Chat',
        'agent' : 'Agent Name',
        'support' : 'Support team'
    },
    'transcript':{
        'meeting' : 'Meeting Transcription'
    },
    'participants' :{
        'share' : 'Share your meeting link',
        'invite' : 'INVITE',
        'all' : 'All Participants'
    },
    'wellness' :{
        'descript' : 'Description',
        'req' : 'Requirements',
        'goal' : 'Target / Goal',
        'date' : 'Start Date',
        'participant' : 'Participants',
        'msg' : 'Message / Posts',
        'leader' : 'Leader Board'
    }
}

export default en_us;